var e = ["{{address.city_prefix}} {{name.firstName}}{{address.city_suffix}}", "{{address.city_prefix}} {{name.firstName}}", "{{name.firstName}}{{address.city_suffix}}", "{{name.lastName}}{{address.city_suffix}}"];
var t = ["Canada"];
var r = ["A#? #?#", "B#? #?#", "C#? #?#", "E#? #?#", "G#? #?#", "H#? #?#", "J#? #?#", "K#? #?#", "L#? #?#", "M#? #?#", "N#? #?#", "P#? #?#", "R#? #?#", "S#? #?#", "T#? #?#", "V#? #?#", "X#? #?#", "Y#? #?#"];
var o = ["Alberta", "Colombie-Britannique", "Manitoba", "Nouveau-Brunswick", "Terre-Neuve-et-Labrador", "Nouvelle-\xC9cosse", "Territoires du Nord-Ouest", "Nunavut", "Ontario", "\xCEle-du-Prince-\xC9douard", "Qu\xE9bec", "Saskatchewan", "Yukon"];
var i = ["AB", "BC", "MB", "NB", "NL", "NS", "NU", "NT", "ON", "PE", "QC", "SK", "YK"];
var a = ["{{name.firstName}} {{address.street_suffix}}", "{{name.lastName}} {{address.street_suffix}}"];
var u = {
    city: e,
    default_country: t,
    postcode: r,
    state: o,
    state_abbr: i,
    street: a
  },
  f = u;
var m = ["qc.ca", "ca", "com", "biz", "info", "name", "net", "org"];
var s = ["gmail.com", "yahoo.ca", "hotmail.com"];
var c = {
    domain_suffix: m,
    free_email: s
  },
  n = c;
var d = ["### ###-####", "1 ### ###-####", "### ###-####, poste ###"];
var l = {
    formats: d
  },
  p = l;
var x = {
    title: "French (Canada)",
    address: f,
    internet: n,
    phone_number: p
  },
  Q = x;
export { Q as a };