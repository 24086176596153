var e = ["{{address.city_prefix}} {{name.firstName}}{{address.city_suffix}}", "{{address.city_prefix}} {{name.firstName}}", "{{name.firstName}}{{address.city_suffix}}", "{{name.lastName}}{{address.city_suffix}}"];
var t = ["Carlow", "Cavan", "Clare", "Cork", "Donegal", "Dublin", "Galway", "Kerry", "Kildare", "Kilkenny", "Laois", "Leitrim", "Limerick", "Longford", "Louth", "Mayo", "Meath", "Monaghan", "Offaly", "Roscommon", "Sligo", "Tipperary", "Waterford", "Westmeath", "Wexford", "Wicklow"];
var r = ["Ireland"];
var o = ["{{name.firstName}} {{address.street_suffix}}", "{{name.lastName}} {{address.street_suffix}}"];
var p = {
    city: e,
    county: t,
    default_country: r,
    street: o
  },
  i = p;
var f = ["082 ### ####", "083 ### ####", "085 ### ####", "086 ### ####", "087 ### ####", "089 ### ####"];
var l = {
    formats: f
  },
  n = l;
var a = ["ie", "com", "net", "info", "eu"];
var u = {
    domain_suffix: a
  },
  m = u;
var s = ["01 #######", "021 #######", "022 #######", "023 #######", "024 #######", "025 #######", "026 #######", "027 #######", "028 #######", "029 #######", "0402 #######", "0404 #######", "041 #######", "042 #######", "043 #######", "044 #######", "045 #######", "046 #######", "047 #######", "049 #######", "0504 #######", "0505 #######", "051 #######", "052 #######", "053 #######", "056 #######", "057 #######", "058 #######", "059 #######", "061 #######", "062 #######", "063 #######", "064 #######", "065 #######", "066 #######", "067 #######", "068 #######", "069 #######", "071 #######", "074 #######", "090 #######", "091 #######", "093 #######", "094 #######", "095 #######", "096 #######", "097 #######", "098 #######", "099 #######"];
var c = {
    formats: s
  },
  d = c;
var x = {
    title: "English (Ireland)",
    address: i,
    cell_phone: n,
    internet: m,
    phone_number: d
  },
  S = x;
export { S as a };