var e = ["#####", "####", "###", "##", "#"];
var t = ["{{address.city_prefix}}{{address.city_suffix}}"];
var r = ["\u4E0A", "\u5305", "\u5317", "\u5357", "\u53A6", "\u5409", "\u592A", "\u5B81", "\u5B89", "\u6210", "\u6B66", "\u6D4E", "\u6D77", "\u73E0", "\u798F", "\u8861", "\u897F", "\u8BF8", "\u8D35", "\u957F"];
var o = ["\u4E61\u53BF", "\u4EAC\u5E02", "\u5357\u5E02", "\u539F\u5E02", "\u53E3\u5E02", "\u5934\u5E02", "\u5B81\u5E02", "\u5B89\u5E02", "\u5DDE\u5E02", "\u5FBD\u5E02", "\u6797\u5E02", "\u6C49\u5E02", "\u6C99\u5E02", "\u6D77\u5E02", "\u7801\u5E02", "\u90FD\u5E02", "\u95E8\u5E02", "\u9633\u5E02"];
var m = ["\u4E2D\u56FD"];
var f = ["######"];
var i = ["\u5317\u4EAC\u5E02", "\u4E0A\u6D77\u5E02", "\u5929\u6D25\u5E02", "\u91CD\u5E86\u5E02", "\u9ED1\u9F99\u6C5F\u7701", "\u5409\u6797\u7701", "\u8FBD\u5B81\u7701", "\u5185\u8499\u53E4", "\u6CB3\u5317\u7701", "\u65B0\u7586", "\u7518\u8083\u7701", "\u9752\u6D77\u7701", "\u9655\u897F\u7701", "\u5B81\u590F", "\u6CB3\u5357\u7701", "\u5C71\u4E1C\u7701", "\u5C71\u897F\u7701", "\u5B89\u5FBD\u7701", "\u6E56\u5317\u7701", "\u6E56\u5357\u7701", "\u6C5F\u82CF\u7701", "\u56DB\u5DDD\u7701", "\u8D35\u5DDE\u7701", "\u4E91\u5357\u7701", "\u5E7F\u897F\u7701", "\u897F\u85CF", "\u6D59\u6C5F\u7701", "\u6C5F\u897F\u7701", "\u5E7F\u4E1C\u7701", "\u798F\u5EFA\u7701", "\u53F0\u6E7E\u7701", "\u6D77\u5357\u7701", "\u9999\u6E2F", "\u6FB3\u95E8"];
var a = ["\u4EAC", "\u6CAA", "\u6D25", "\u6E1D", "\u9ED1", "\u5409", "\u8FBD", "\u8499", "\u5180", "\u65B0", "\u7518", "\u9752", "\u9655", "\u5B81", "\u8C6B", "\u9C81", "\u664B", "\u7696", "\u9102", "\u6E58", "\u82CF", "\u5DDD", "\u9ED4", "\u6EC7", "\u6842", "\u85CF", "\u6D59", "\u8D63", "\u7CA4", "\u95FD", "\u53F0", "\u743C", "\u6E2F", "\u6FB3"];
var s = ["{{name.last_name}}{{address.street_suffix}}"];
var d = {
  normal: "{{address.street}}{{address.buildingNumber}}\u53F7",
  full: "{{address.street}}{{address.buildingNumber}}\u53F7 {{address.secondaryAddress}}"
};
var p = ["\u5DF7", "\u8857", "\u8DEF", "\u6865", "\u4FAC", "\u65C1", "\u4E2D\u5FC3", "\u680B"];
var N = {
    building_number: e,
    city: t,
    city_prefix: r,
    city_suffix: o,
    default_country: m,
    postcode: f,
    state: i,
    state_abbr: a,
    street: s,
    street_address: d,
    street_suffix: p
  },
  n = N;
var u = ["\u79C0\u82F1", "\u79C0\u5170", "\u79C0\u73CD", "\u6842\u82F1", "\u6842\u5170", "\u7389\u5170", "\u7389\u73CD", "\u7389\u82F1", "\u7389\u6885", "\u51E4\u82F1", "\u5170\u82F1", "\u5A77\u5A77", "\u6D77\u71D5", "\u56FD\u82F1", "\u56FD\u73CD", "\u56FD\u534E", "\u56FD\u82B3", "\u56FD\u5170", "\u56FD\u79C0", "\u56FD\u7434", "\u56FD\u8363", "\u56FD\u9999", "\u82F1", "\u840D", "\u7EA2", "\u4E3D", "\u654F", "\u82B3", "\u9759", "\u971E", "\u71D5", "\u5A1F", "\u8273", "\u5A1C", "\u4E39", "\u73B2", "\u5A77", "\u96EA", "\u5029", "\u60A6", "\u9896", "\u6D01", "\u4E3D\u82AC", "\u4E3D\u82B3", "\u4E3D\u840D", "\u82E5\u6C50", "\u4E00\u8BFA", "\u827A\u6DB5", "\u4F9D\u8BFA", "\u6893\u6DB5", "\u6893\u6668", "\u6893\u99A8", "\u6893\u8431", "\u6893\u598D", "\u6893\u73A5", "\u82E1\u6CAB", "\u96E8\u6850", "\u6B23\u6021", "\u8BED\u6850", "\u8BED\u6C50", "\u96E8\u6DB5", "\u96E8\u6B23"];
var l = ["\u4E50\u9A79", "\u4F1F\u5BB8", "\u4F1F\u6CFD", "\u4F1F\u797A", "\u4F1F\u8BDA", "\u4FCA\u9A70", "\u4FEE\u6770", "\u4FEE\u6D01", "\u5065\u67CF", "\u5065\u96C4", "\u51EF\u745E", "\u535A\u6587", "\u535A\u6D9B", "\u535A\u8D85", "\u541B\u6D69", "\u54F2\u701A", "\u5609\u61FF", "\u5609\u7199", "\u5929\u5B87", "\u5929\u78CA", "\u5929\u7FCA", "\u5B50\u6DB5", "\u5B50\u8F69", "\u5B50\u9A9E", "\u5B50\u9ED8", "\u5C55\u9E4F", "\u5CFB\u7199", "\u5EFA\u8F89", "\u5F18\u6587", "\u5F6C", "\u5FD7\u5F3A", "\u5FD7\u6CFD", "\u601D", "\u601D\u6DFC", "\u601D\u6E90", "\u601D\u806A", "\u601D\u8FDC", "\u61FF\u8F69", "\u632F\u5BB6", "\u64CE\u5B87", "\u64CE\u82CD", "\u6587", "\u6587\u535A", "\u6587\u660A", "\u6587\u8F69", "\u65ED\u5C27", "\u660A\u5929", "\u660A\u5F3A", "\u660A\u7131", "\u660A\u7136", "\u660E", "\u660E\u54F2", "\u660E\u6770", "\u660E\u8F69", "\u660E\u8F89", "\u664B\u9E4F", "\u6653\u535A", "\u6653\u5578", "\u665F\u777F", "\u667A\u5BB8", "\u667A\u6E0A", "\u667A\u8F89", "\u679C", "\u6893\u6668", "\u6977\u745E", "\u6B63\u8C6A", "\u6CFD\u6D0B", "\u6D69", "\u6D69\u5B87", "\u6D69\u7136", "\u6D69\u8F69", "\u6D9B", "\u6F47\u7136", "\u708E\u5F6C", "\u70AB\u660E", "\u70E8\u4F1F", "\u70E8\u534E", "\u70E8\u78CA", "\u70E8\u9716", "\u715C\u57CE", "\u715C\u797A", "\u71A0\u5F64", "\u742A", "\u745E\u9716", "\u747E\u745C", "\u7693\u8F69", "\u777F\u6E0A", "\u7ACB\u679C", "\u7ACB\u8BDA", "\u7ACB\u8F69", "\u7ACB\u8F89", "\u7B11\u611A", "\u7ECD\u8F89", "\u7ECD\u9F50", "\u8000\u6770", "\u806A\u5065", "\u80E4\u7965", "\u81F4\u8FDC", "\u822A", "\u82D1\u535A", "\u8363\u8F69", "\u8BED\u5802", "\u8D8A\u5F6C", "\u8D8A\u6CFD", "\u8FDC\u822A", "\u91D1\u946B", "\u946B\u78CA", "\u946B\u9E4F", "\u94B0\u8F69", "\u9526\u7A0B", "\u96E8\u6CFD", "\u96EA\u677E", "\u9756\u742A", "\u98CE\u534E", "\u9A70", "\u9E3F\u6D9B", "\u9E3F\u714A", "\u9E4F", "\u9E4F\u6D9B", "\u9E4F\u714A", "\u9E4F\u98DE", "\u9E64\u8F69", "\u9E6D\u6D0B", "\u9ECE\u6615"];
var x = ["\u738B", "\u674E", "\u5F20", "\u5218", "\u9648", "\u6768", "\u9EC4", "\u5434", "\u8D75", "\u5468", "\u5F90", "\u5B59", "\u9A6C", "\u6731", "\u80E1", "\u6797", "\u90ED", "\u4F55", "\u9AD8", "\u7F57", "\u90D1", "\u6881", "\u8C22", "\u5B8B", "\u5510", "\u8BB8", "\u9093", "\u51AF", "\u97E9", "\u66F9", "\u66FE", "\u5F6D", "\u8427", "\u8521", "\u6F58", "\u7530", "\u8463", "\u8881", "\u4E8E", "\u4F59", "\u53F6", "\u848B", "\u675C", "\u82CF", "\u9B4F", "\u7A0B", "\u5415", "\u4E01", "\u6C88", "\u4EFB", "\u59DA", "\u5362", "\u5085", "\u949F", "\u59DC", "\u5D14", "\u8C2D", "\u5ED6", "\u8303", "\u6C6A", "\u9646", "\u91D1", "\u77F3", "\u6234", "\u8D3E", "\u97E6", "\u590F", "\u90B1", "\u65B9", "\u4FAF", "\u90B9", "\u718A", "\u5B5F", "\u79E6", "\u767D", "\u6C5F", "\u960E", "\u859B", "\u5C39", "\u6BB5", "\u96F7", "\u9ECE", "\u53F2", "\u9F99", "\u9676", "\u8D3A", "\u987E", "\u6BDB", "\u90DD", "\u9F9A", "\u90B5", "\u4E07", "\u94B1", "\u4E25", "\u8D56", "\u8983", "\u6D2A", "\u6B66", "\u83AB", "\u5B54", "\u6B27\u9633", "\u6155\u5BB9", "\u53F8\u9A6C", "\u4EE4\u72D0", "\u4EF2\u5B59", "\u949F\u79BB", "\u957F\u5B59", "\u5B87\u6587", "\u53F8\u5F92", "\u9C9C\u4E8E", "\u53F8\u7A7A"];
var _ = ["\u5EFA\u534E", "\u5EFA\u56FD", "\u5EFA\u519B", "\u56FD\u5F3A", "\u56FD\u5E73", "\u56FD\u826F", "\u56FD\u680B", "\u56FD\u8F89", "\u5FD7\u56FD", "\u5FD7\u660E", "\u52C7", "\u519B", "\u4F1F", "\u5F3A", "\u521A", "\u6D9B", "\u658C", "\u6CE2", "\u8F89", "\u78CA", "\u8D85", "\u9E4F", "\u6770", "\u6D69", "\u946B", "\u5E05", "\u5B87", "\u6668", "\u8BDA", "\u6210", "\u6C11", "\u660E", "\u9633", "\u7199\u6210", "\u7199\u7476", "\u5BB6\u8C6A", "\u5BB6\u660E", "\u4FCA\u6770", "\u4FCA\u7199", "\u6C90\u5BB8", "\u6D69\u5B87", "\u6D69\u7136", "\u6D69\u8F69", "\u6D69\u6668", "\u6C90\u8FB0", "\u8317\u6CFD", "\u5955\u8FB0", "\u5955\u6CFD", "\u5B87\u6CFD", "\u5B87\u8F69", "\u5B87\u822A", "\u6C90\u9633", "\u6893\u8BDA", "\u6893\u8C6A", "\u6893\u777F", "\u6893\u6D69"];
var c = ["{{name.first_name}}{{name.last_name}}"];
var h = {
    female_first_name: u,
    first_name: l,
    last_name: x,
    male_first_name: _,
    name: c
  },
  b = h;
var y = ["0##-########", "0###-########", "1##########"];
var g = {
    formats: y
  },
  D = g;
var A = {
    title: "Chinese",
    address: n,
    name: b,
    phone_number: D
  },
  pe = A;
export { pe as a };