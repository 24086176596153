var a = ["Aino", "Anja", "Anna", "Anne", "Anneli", "Annikki", "Eeva", "Elina", "Elisabet", "Emilia", "Eveliina", "Hanna", "Hannele", "Helena", "Inkeri", "Irmeli", "Johanna", "Kaarina", "Karoliina", "Katariina", "Kristiina", "Kyllikki", "Laura", "Leena", "Liisa", "Maarit", "Maija", "Mari", "Maria", "Marika", "Marja", "Marjatta", "Minna", "Orvokki", "Pauliina", "Pirjo", "Pirkko", "P\xE4ivi", "Riitta", "Ritva", "Sari", "Satu", "Sinikka", "Sofia", "Susanna", "Tarja", "Tellervo", "Tiina", "Tuula", "Tuulikki"];
var i = ["Aleksi", "Antero", "Antti", "Ari", "Eero", "Ensio", "Erik", "Erkki", "Hannu", "Heikki", "Henrik", "Ilmari", "Jaakko", "Janne", "Jari", "Johannes", "Juha", "Juhani", "Juho", "Jukka", "Kalervo", "Kalevi", "Kari", "Kristian", "Lauri", "Markku", "Marko", "Markus", "Martti", "Matias", "Matti", "Mika", "Mikael", "Mikko", "Olavi", "Oskari", "Pekka", "Pentti", "Petri", "Petteri", "Sakari", "Sami", "Seppo", "Tapani", "Tapio", "Timo", "Tuomas", "Valtteri", "Veikko", "Ville", "Aino", "Anja", "Anna", "Anne", "Anneli", "Annikki", "Eeva", "Elina", "Elisabet", "Emilia", "Eveliina", "Hanna", "Hannele", "Helena", "Inkeri", "Irmeli", "Johanna", "Kaarina", "Karoliina", "Katariina", "Kristiina", "Kyllikki", "Laura", "Leena", "Liisa", "Maarit", "Maija", "Mari", "Maria", "Marika", "Marja", "Marjatta", "Minna", "Orvokki", "Pauliina", "Pirjo", "Pirkko", "P\xE4ivi", "Riitta", "Ritva", "Sari", "Satu", "Sinikka", "Sofia", "Susanna", "Tarja", "Tellervo", "Tiina", "Tuula", "Tuulikki"];
var n = ["Aaltonen", "Ahonen", "Anttila", "Hakala", "Heikkil\xE4", "Heikkinen", "Heinonen", "Hiltunen", "Hirvonen", "H\xE4m\xE4l\xE4inen", "Jokinen", "J\xE4rvinen", "Kallio", "Karjalainen", "Kinnunen", "Koivisto", "Korhonen", "Koskinen", "Laakso", "Laaksonen", "Lahtinen", "Laine", "Laitinen", "Lehtinen", "Lehto", "Lehtonen", "Leinonen", "Lepp\xE4nen", "Manninen", "Mattila", "Miettinen", "Mustonen", "M\xE4kel\xE4", "M\xE4kinen", "Niemi", "Nieminen", "Ojala", "Pitk\xE4nen", "Rantanen", "R\xE4s\xE4nen", "Saarinen", "Salminen", "Salo", "Salonen", "Savolainen", "Toivonen", "Tuominen", "Turunen", "Virtanen", "V\xE4is\xE4nen"];
var e = ["Aleksi", "Antero", "Antti", "Ari", "Eero", "Ensio", "Erik", "Erkki", "Hannu", "Heikki", "Henrik", "Ilmari", "Jaakko", "Janne", "Jari", "Johannes", "Juha", "Juhani", "Juho", "Jukka", "Kalervo", "Kalevi", "Kari", "Kristian", "Lauri", "Markku", "Marko", "Markus", "Martti", "Matias", "Matti", "Mika", "Mikael", "Mikko", "Olavi", "Oskari", "Pekka", "Pentti", "Petri", "Petteri", "Sakari", "Sami", "Seppo", "Tapani", "Tapio", "Timo", "Tuomas", "Valtteri", "Veikko", "Ville"];
var r = ["{{name.first_name}} {{name.last_name}}", "{{name.male_first_name}} {{name.last_name}}", "{{name.female_first_name}} {{name.last_name}}"];
var o = {
    female_first_name: a,
    first_name: i,
    last_name: n,
    male_first_name: e,
    name: r
  },
  t = o;
var k = {
    title: "Finnish",
    name: t
  },
  J = k;
export { J as a };