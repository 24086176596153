var e = ["{{address.city_prefix}} {{name.firstName}}{{address.city_suffix}}", "{{address.city_prefix}} {{name.firstName}}", "{{name.firstName}}{{address.city_suffix}}", "{{name.lastName}}{{address.city_suffix}}"];
var a = ["South Africa"];
var i = ["#####", "####"];
var o = ["{{name.firstName}} {{address.street_suffix}}", "{{name.lastName}} {{address.street_suffix}}"];
var k = {
    city: e,
    default_country: a,
    postcode: i,
    street: o
  },
  n = k;
var l = ["082 ### ####", "084 ### ####", "083 ### ####", "065 ### ####", "082#######", "082 #######"];
var y = {
    formats: l
  },
  t = y;
var u = ["Pty Ltd", "Ltd", "CC"];
var S = {
    suffix: u
  },
  h = S;
var s = ["co.za", "com", "org.za", "info", "net.za"];
var w = {
    domain_suffix: s
  },
  m = w;
var r = ["Amahle", "Ayanda", "Ayize", "Buhle", "Busisiwe", "Duduzile", "Hlengiwe", "Inyoni", "Isisa", "Jabulile", "Khanyisile", "Khethiwe", "Khulekani", "Lerato", "Liyana", "Lukhona", "Mbalienhle", "Mhambi", "Mthunzi", "Nandi", "Ndondoloza", "Nhlakanipho", "Nkosingiphile", "Nofoto", "Nokuthula", "Nolwazi", "Nomvula", "Nonhlanhla", "Nonjabulo", "Nonkululeko", "Nozipho", "Ntokozo", "Ntombizodwa", "Samukelisiwe", "Sibongile", "Sindisiwe", "Sinenhlanhla", "Siphephelo", "Sizakele", "Sizani", "Thabisa", "Thadie", "Thobeka", "Thulisile", "Uluthando", "Ulwazi", "Yibanathi", "Zanele", "Zobuhle"];
var f = ["Amahle", "Anele", "Ayanda", "Ayize", "Bafana", "Bangizwe", "Bhekimuzi", "Bhekizizwe", "Bhekumbuso", "Bonginkosi", "Buhle", "Busisiwe", "Duduzile", "Dumisani", "Fanyana", "Funani", "Gatsha", "Hlengiwe", "Inyoni", "Isisa", "Jabulile", "Kagiso", "Kgabu", "Khanyisile", "Khethiwe", "Khulekani", "Langa", "Lerato", "Linda", "Liyana", "Lukhona", "Lungelo", "Lwandle", "Mandla", "Maphikelela", "Mbalienhle", "Mhambi", "Mpilo", "Mpumelelo", "Msizi", "Mthunzi", "Musawenkosi", "Nandi", "Ndleleni", "Ndondoloza", "Nhlakanipho", "Nkanyezi", "Nkosenye", "Nkosingiphile", "Nkosinhle", "Nkosiyabo", "Nofoto", "Nokuthula", "Nolwazi", "Nomvula", "Nonhlanhla", "Nonjabulo", "Nonkululeko", "Nozipho", "Ntokozo", "Ntombizodwa", "Phila", "Philani", "Phiwokwakhe", "Samukelisiwe", "Sanele", "Sfiso", "Shaka", "Sibongile", "Sibusiso", "Sifiso", "Sindisiwe", "Sinenhlanhla", "Siphephelo", "Siphiwe", "Sipho", "Sithembiso", "Siyanda", "Sizakele", "Sizani", "Solomon", "Thabisa", "Thadie", "Thamsanqa", "Thando", "Themba", "Thobeka", "Thulani", "Thulisile", "Uluthando", "Ulwazi", "Velaphi", "Vusumuzi", "Yibanathi", "Zanele", "Zenzele", "Zithulele", "Zobuhle"];
var d = ["Bayeni", "Bengu", "Bhembe", "Bhengani", "Bhengu", "Bhuyeni", "Biyela", "Buthelezi", "Cebekhulu", "Cele", "Chiliza", "Delazy", "Dhlomo", "Dingiswayo", "Dlamini", "Dube", "Fuze", "Gabhezi", "Gama", "Gigaba", "Guliwe", "Gumede", "Gwacela", "Gwala", "Hlongwa", "Khawula", "Khumalo", "Khuzwayo", "Kunene", "Lamula", "Lembede", "Luthuli", "Mabhida", "Mabizela", "Maduma", "Magoza", "Malinga", "Maphisa", "Mashinini", "Mavundla", "Mbatha", "Mbende", "Mbuso", "Mehloluhlaza", "Mfeka", "Mfumu", "Mgenge", "Mkhatshwa", "Moseley", "Mqwebu", "Msibi", "Mthembu", "Mthethwa", "Mtolo", "Musi", "Ncusi", "Ndandali", "Ndebele", "Ngcolosi", "Ngema", "Ngubane", "Ngwazi", "Nhleko", "Nomvethe", "Nondlela", "Nonyana", "Nozulu", "Nsele", "Ntanzi", "Ntombela", "Nyanda", "Nzimande", "Radebe", "Seme", "Senzangakhona", "Shezi", "Sibiya", "Sithuli", "Siwele", "Siyaya", "Sokhela", "Sondisa", "Sothole", "Thoyana", "Thumbeza", "Thusi", "Tshabalala", "Vezi", "Vilakazi", "Wosiyane", "Yengwa", "Zondi", "Zondo", "Zubane", "Zuma", "Zungu"];
var p = ["Bonginkosi", "Kagiso", "Bhekizizwe", "Bhekumbuso", "Shaka", "Funani", "Kgabu", "Solomon", "Gatsha", "Langa", "Phila", "Msizi", "Nkosiyabo", "Linda", "Mpilo", "Siyanda", "Nkanyezi", "Bafana", "Lwandle", "Sfiso", "Thulani", "Thando", "Sanele", "Anele", "Lungelo", "Dumisani", "Bangizwe", "Fanyana", "Bhekimuzi", "Mandla", "Maphikelela", "Mpumelelo", "Mthunzi", "Philani", "Musawenkosi", "Nkosenye", "Nkosinhle", "Phiwokwakhe", "Sifiso", "Zithulele", "Sithembiso", "Sipho", "Siphiwe", "Sibusiso", "Velaphi", "Thamsanqa", "Vusumuzi", "Themba", "Zenzele", "Ndleleni"];
var M = {
    female_first_name: r,
    first_name: f,
    last_name: d,
    male_first_name: p
  },
  N = M;
var z = ["01# ### #####", "02# ### #####", "03# ### #####", "04# ### #####", "05# ### #####", "0800 ### ###", "0860 ### ###", "01#########", "01# ########"];
var g = {
    formats: z
  },
  b = g;
var c = {
    title: "Zulu (South Africa)",
    address: n,
    cell_phone: t,
    company: h,
    internet: m,
    name: N,
    phone_number: b
  },
  ue = c;
export { ue as a };