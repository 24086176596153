import memoize from 'lodash.memoize';
function resolver(options) {
  return JSON.stringify(options);
}
function isString(el) {
  return typeof el === 'string';
}
function isUnique(el, index, arr) {
  return arr.indexOf(el) === index;
}
function isAllLowerCase(el) {
  return el.toLowerCase() === el;
}
function fixCommas(el) {
  return el.indexOf(',') === -1 ? el : el.split(',');
}
function normalizeLocale(locale) {
  if (!locale) {
    return locale;
  }
  if (locale === 'C' || locale === 'posix' || locale === 'POSIX') {
    return 'en-US';
  }
  // If there's a dot (.) in the locale, it's likely in the format of "en-US.UTF-8", so we only take the first part
  if (locale.indexOf('.') !== -1) {
    var _a = locale.split('.')[0],
      actualLocale = _a === void 0 ? '' : _a;
    return normalizeLocale(actualLocale);
  }
  // If there's an at sign (@) in the locale, it's likely in the format of "en-US@posix", so we only take the first part
  if (locale.indexOf('@') !== -1) {
    var _b = locale.split('@')[0],
      actualLocale = _b === void 0 ? '' : _b;
    return normalizeLocale(actualLocale);
  }
  // If there's a dash (-) in the locale and it's not all lower case, it's already in the format of "en-US", so we return it
  if (locale.indexOf('-') === -1 || !isAllLowerCase(locale)) {
    return locale;
  }
  var _c = locale.split('-'),
    splitEl1 = _c[0],
    _d = _c[1],
    splitEl2 = _d === void 0 ? '' : _d;
  return "".concat(splitEl1, "-").concat(splitEl2.toUpperCase());
}
function getUserLocalesInternal(_a) {
  var _b = _a === void 0 ? {} : _a,
    _c = _b.useFallbackLocale,
    useFallbackLocale = _c === void 0 ? true : _c,
    _d = _b.fallbackLocale,
    fallbackLocale = _d === void 0 ? 'en-US' : _d;
  var languageList = [];
  if (typeof navigator !== 'undefined') {
    var rawLanguages = navigator.languages || [];
    var languages = [];
    for (var _i = 0, rawLanguages_1 = rawLanguages; _i < rawLanguages_1.length; _i++) {
      var rawLanguagesItem = rawLanguages_1[_i];
      languages = languages.concat(fixCommas(rawLanguagesItem));
    }
    var rawLanguage = navigator.language;
    var language = rawLanguage ? fixCommas(rawLanguage) : rawLanguage;
    languageList = languageList.concat(languages, language);
  }
  if (useFallbackLocale) {
    languageList.push(fallbackLocale);
  }
  return languageList.filter(isString).map(normalizeLocale).filter(isUnique);
}
export var getUserLocales = memoize(getUserLocalesInternal, resolver);
function getUserLocaleInternal(options) {
  return getUserLocales(options)[0] || null;
}
export var getUserLocale = memoize(getUserLocaleInternal, resolver);
export default getUserLocale;