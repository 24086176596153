import { a as ro } from "./chunk-WQ5WD4ZB.mjs";
import { a as mo } from "./chunk-N54TB7R3.mjs";
import { a as V } from "./chunk-CNLXCJ2I.mjs";
import { a as X } from "./chunk-2ARF2KYP.mjs";
import { a as J } from "./chunk-WJNWABYE.mjs";
import { a as Q } from "./chunk-BG5XSEAX.mjs";
import { a as Y } from "./chunk-WTFAB2OK.mjs";
import { a as $ } from "./chunk-4WRXY4YA.mjs";
import { a as oo } from "./chunk-PHKD4ARO.mjs";
import { a as eo } from "./chunk-NFMQMJTZ.mjs";
import { a as K } from "./chunk-USQH6BW5.mjs";
import { a as R } from "./chunk-EZQM6TIL.mjs";
import { a as Z } from "./chunk-DHYAFIBN.mjs";
import { a as O } from "./chunk-5ZAQ2U6R.mjs";
import { a as P } from "./chunk-ZJOZA6GK.mjs";
import { a as W } from "./chunk-BDWC2LEF.mjs";
import { a as j } from "./chunk-7M4JXUUT.mjs";
import { a as q } from "./chunk-FCSWVLM3.mjs";
import { a as U } from "./chunk-NOKPGS4X.mjs";
import { a as G } from "./chunk-7F72AJZR.mjs";
import { a as H } from "./chunk-Z627URUV.mjs";
import { a as I } from "./chunk-HA3GPRO5.mjs";
import { a as b } from "./chunk-OEKYCRFB.mjs";
import { a as g } from "./chunk-W7Z3VRYQ.mjs";
import { a as v } from "./chunk-RBYSY45B.mjs";
import { a as w } from "./chunk-SBNWBO2R.mjs";
import { a as S } from "./chunk-Z6T3C6NC.mjs";
import { a as N } from "./chunk-EB7QWEJK.mjs";
import { a as F } from "./chunk-JIMEU6GT.mjs";
import { a as L } from "./chunk-YNMXESCZ.mjs";
import { a as E } from "./chunk-572NMBBA.mjs";
import { a as z } from "./chunk-HMKBSESR.mjs";
import { a as B } from "./chunk-4QDT4GR4.mjs";
import { a as T } from "./chunk-MYXNWJ2J.mjs";
import { a as y } from "./chunk-VK7Z6RGB.mjs";
import { a as x } from "./chunk-BDJXH623.mjs";
import { a as D } from "./chunk-NOA2ZY6V.mjs";
import { a as M } from "./chunk-SKS62GR6.mjs";
import { a as C } from "./chunk-TJ6YH7C2.mjs";
import { a as h } from "./chunk-PTPXZ7BA.mjs";
import { a as k } from "./chunk-3BX74TNW.mjs";
import { a as A } from "./chunk-VZILLPE6.mjs";
import { a as f } from "./chunk-QKDMGTDO.mjs";
import { a as s } from "./chunk-MBBBPC24.mjs";
import { a as d } from "./chunk-WW46KXGA.mjs";
import { a } from "./chunk-UCPFQJLO.mjs";
import { a as u } from "./chunk-NQOA56Q7.mjs";
import { a as _ } from "./chunk-YTYAKGI6.mjs";
import { a as c } from "./chunk-KMHR4YDU.mjs";
import { a as e } from "./chunk-XOZOSSLW.mjs";
import { a as r } from "./chunk-YOA26RGT.mjs";
import { a as m } from "./chunk-GDLFIL7K.mjs";
import { a as t } from "./chunk-AC7CIJLS.mjs";
import { a as i } from "./chunk-3ARMJFIB.mjs";
import { a as n } from "./chunk-OP2QW3G5.mjs";
import { a as p } from "./chunk-DWQIXVVW.mjs";
import { a as io, b as no, c as po, d as o, e as l } from "./chunk-G6GIS7LZ.mjs";
var fo = {
    af_ZA: e,
    ar: r,
    az: m,
    cz: t,
    de: i,
    de_AT: n,
    de_CH: p,
    el: f,
    en: l,
    en_AU: s,
    en_AU_ocker: d,
    en_BORK: a,
    en_CA: u,
    en_GB: _,
    en_GH: c,
    en_IE: y,
    en_IND: x,
    en_NG: D,
    en_US: M,
    en_ZA: C,
    es: h,
    es_MX: k,
    fa: A,
    fi: S,
    fr: N,
    fr_BE: F,
    fr_CA: L,
    fr_CH: E,
    ge: z,
    he: B,
    hr: T,
    hu: U,
    hy: G,
    id_ID: H,
    it: I,
    ja: b,
    ko: g,
    lv: v,
    mk: w,
    nb_NO: K,
    ne: R,
    nl: Z,
    nl_BE: O,
    pl: P,
    pt_BR: W,
    pt_PT: j,
    ro: q,
    ru: V,
    sk: X,
    sv: J,
    tr: Q,
    uk: Y,
    ur: $,
    vi: oo,
    zh_CN: eo,
    zh_TW: ro,
    zu_ZA: mo
  },
  to = fo;
var ye = new o({
  locales: to
});
export { o as Faker, io as FakerError, no as Gender, po as Sex, ye as faker };