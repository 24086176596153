var a = ["{{address.city_prefix}} {{name.firstName}}{{address.city_suffix}}", "{{address.city_prefix}} {{name.firstName}}", "{{name.firstName}}{{address.city_suffix}}", "{{name.lastName}}{{address.city_suffix}}", "{{address.city_name}}"];
var t = ["Bhaktapur", "Biratnagar", "Birendranagar", "Birgunj", "Butwal", "Damak", "Dharan", "Gaur", "Gorkha", "Hetauda", "Itahari", "Janakpur", "Kathmandu", "Lahan", "Nepalgunj", "Pokhara"];
var i = ["Nepal"];
var r = ["Baglung", "Banke", "Bara", "Bardiya", "Bhaktapur", "Bhojupu", "Chitwan", "Dailekh", "Dang", "Dhading", "Dhankuta", "Dhanusa", "Dolakha", "Dolpha", "Gorkha", "Gulmi", "Humla", "Ilam", "Jajarkot", "Jhapa", "Jumla", "Kabhrepalanchok", "Kalikot", "Kapilvastu", "Kaski", "Kathmandu", "Lalitpur", "Lamjung", "Manang", "Mohottari", "Morang", "Mugu", "Mustang", "Myagdi", "Nawalparasi", "Nuwakot", "Palpa", "Parbat", "Parsa", "Ramechhap", "Rauswa", "Rautahat", "Rolpa", "Rupandehi", "Sankhuwasabha", "Sarlahi", "Sindhuli", "Sindhupalchok", "Sunsari", "Surket", "Syangja", "Tanahu", "Terhathum"];
var e = ["{{name.firstName}} {{address.street_suffix}}", "{{name.lastName}} {{address.street_suffix}}"];
var y = {
    city: a,
    city_name: t,
    default_country: i,
    state: r,
    street: e
  },
  n = y;
var o = ["Pvt Ltd", "Group", "Ltd", "Limited"];
var c = {
    suffix: o
  },
  m = c;
var s = ["np", "com", "info", "net", "org"];
var u = ["worldlink.com.np", "gmail.com", "yahoo.com", "hotmail.com"];
var S = {
    domain_suffix: s,
    free_email: u
  },
  p = S;
var h = ["Aarav", "Ajita", "Amit", "Amita", "Amrit", "Arijit", "Ashmi", "Asmita", "Bibek", "Bijay", "Bikash", "Bina", "Bishal", "Bishnu", "Buddha", "Deepika", "Dipendra", "Gagan", "Ganesh", "Khem", "Krishna", "Laxmi", "Manisha", "Nabin", "Nikita", "Niraj", "Nischal", "Padam", "Pooja", "Prabin", "Prakash", "Prashant", "Prem", "Purna", "Rajendra", "Rajina", "Raju", "Rakesh", "Ranjan", "Ratna", "Sagar", "Sandeep", "Sanjay", "Santosh", "Sarita", "Shilpa", "Shirisha", "Shristi", "Siddhartha", "Subash", "Sumeet", "Sunita", "Suraj", "Susan", "Sushant"];
var f = ["Adhikari", "Aryal", "Baral", "Basnet", "Bastola", "Basynat", "Bhandari", "Bhattarai", "Chettri", "Devkota", "Dhakal", "Dongol", "Ghale", "Gurung", "Gyawali", "Hamal", "Jung", "KC", "Kafle", "Karki", "Khadka", "Koirala", "Lama", "Limbu", "Magar", "Maharjan", "Niroula", "Pandey", "Pradhan", "Rana", "Raut", "Sai", "Shai", "Shakya", "Sherpa", "Shrestha", "Subedi", "Tamang", "Thapa"];
var x = {
    first_name: h,
    last_name: f
  },
  d = x;
var l = ["##-#######", "+977-#-#######", "+977########"];
var D = {
    formats: l
  },
  k = D;
var g = {
    title: "Nepalese",
    address: n,
    company: m,
    internet: p,
    name: d,
    phone_number: k
  },
  aa = g;
export { aa as a };