var e = ["####", "###", "##"];
var a = ["{{address.city_prefix}}"];
var r = ["Bondi", "Burleigh Heads", "Carlton", "Fitzroy", "Fremantle", "Glenelg", "Manly", "Noosa", "Stones Corner", "St Kilda", "Surry Hills", "Yarra Valley"];
var o = ["Australia"];
var t = ["0###", "2###", "3###", "4###", "5###", "6###", "7###"];
var i = ["South East Queensland", "Wide Bay Burnett", "Margaret River", "Port Pirie", "Gippsland", "Elizabeth", "Barossa"];
var n = ["New South Wales", "Queensland", "Northern Territory", "South Australia", "Western Australia", "Tasmania", "Australian Capital Territory", "Victoria"];
var l = ["NSW", "QLD", "NT", "SA", "WA", "TAS", "ACT", "VIC"];
var m = ["{{address.street_name}}"];
var s = ["Ramsay Street", "Bonnie Doon", "Cavill Avenue", "Queen Street"];
var p = ["Avenue", "Boulevard", "Circle", "Circuit", "Court", "Crescent", "Crest", "Drive", "Estate Dr", "Grove", "Hill", "Island", "Junction", "Knoll", "Lane", "Loop", "Mall", "Manor", "Meadow", "Mews", "Parade", "Parkway", "Pass", "Place", "Plaza", "Ridge", "Road", "Run", "Square", "Station St", "Street", "Summit", "Terrace", "Track", "Trail", "View Rd", "Way"];
var L = {
    building_number: e,
    city: a,
    city_prefix: r,
    default_country: o,
    postcode: t,
    region: i,
    state: n,
    state_abbr: l,
    street: m,
    street_name: s,
    street_suffix: p
  },
  f = L;
var u = ["Pty Ltd", "and Sons", "Corp", "Group", "Brothers", "Partners"];
var M = {
    suffix: u
  },
  d = M;
var y = ["com.au", "com", "net.au", "net", "org.au", "org"];
var _ = {
    domain_suffix: y
  },
  c = _;
var h = ["Charlotte", "Ava", "Chloe", "Emily", "Olivia", "Zoe", "Lily", "Sophie", "Amelia", "Sofia", "Ella", "Isabella", "Ruby", "Sienna", "Mia+3", "Grace", "Emma", "Ivy", "Layla", "Abigail", "Isla", "Hannah", "Zara", "Lucy", "Evie", "Annabelle", "Madison", "Alice", "Georgia", "Maya", "Madeline", "Audrey", "Scarlett", "Isabelle", "Chelsea", "Mila", "Holly", "Indiana", "Poppy", "Harper", "Sarah", "Alyssa", "Jasmine", "Imogen", "Hayley", "Pheobe", "Eva", "Evelyn", "Mackenzie", "Ayla", "Oliver", "Jack", "Jackson", "William", "Ethan", "Charlie", "Lucas", "Cooper", "Lachlan", "Noah", "Liam", "Alexander", "Max", "Isaac", "Thomas", "Xavier", "Oscar", "Benjamin", "Aiden", "Mason", "Samuel", "James", "Levi", "Riley", "Harrison", "Ryan", "Henry", "Jacob", "Joshua", "Leo", "Zach", "Harry", "Hunter", "Flynn", "Archie", "Tyler", "Elijah", "Hayden", "Jayden", "Blake", "Archer", "Ashton", "Sebastian", "Zachery", "Lincoln", "Mitchell", "Luca", "Nathan", "Kai", "Connor", "Tom", "Nigel", "Matt", "Sean"];
var x = ["Smith", "Jones", "Williams", "Brown", "Wilson", "Taylor", "Morton", "White", "Martin", "Anderson", "Thompson", "Nguyen", "Thomas", "Walker", "Harris", "Lee", "Ryan", "Robinson", "Kelly", "King", "Rausch", "Ridge", "Connolly", "LeQuesne"];
var A = ["Bazza", "Bluey", "Davo", "Johno", "Shano", "Shazza"];
var v = {
    first_name: h,
    last_name: x,
    ocker_first_name: A
  },
  S = v;
var C = ["0# #### ####", "+61 # #### ####", "04## ### ###", "+61 4## ### ###"];
var D = {
    formats: C
  },
  b = D;
var g = {
    title: "English (Australia Ocker)",
    address: f,
    company: d,
    internet: c,
    name: S,
    phone_number: b
  },
  Ae = g;
export { Ae as a };